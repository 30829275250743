import * as React from "react"
// import { Link } from "gatsby"

import { Link } from "gatsby-theme-material-ui";
import Layout from "../layout"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  textAlign: "center"

}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64
}

const paragraphStyles = {
  marginBottom: 48,
}

// markup
const NotFoundPage = () => {
  return (

    <Layout>
      <main style={pageStyles}>
        <h1 style={headingStyles}>Página no encontrada.</h1>
        <p style={paragraphStyles}>
          <Link to="/">Ir a pagina de inicio.</Link>.
        </p>
      </main>
    </Layout>
  )
}

export default NotFoundPage
